@tailwind base;
@tailwind components;
@tailwind utilities;

.head{
    background-color: white;
}
.head:hover.box-top{
    box-shadow: inset 0px  5px  rgba(255, 217, 0, 0.635);
}
.flip-card {
    background-color: transparent;
    width: 250px;
    height: 380px;
    margin: auto;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
   
    color: black;
  }
  
  .flip-card-back {
    background-color: #ffffff;
    
    transform: rotateY(180deg);
  }
  .scroll{
    background-color: white !important;
    border-radius: 50% !important;
    color: #ffffff !important;
    padding: 10px !important;
    width: 50px !important;
    height: 50px !important;

  }
  .fontstyle{
    font-family:'Times New Roman', Times, serif
  }